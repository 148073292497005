(function ($) {
  customerService3ColRowV1 = {
    formatter: $('.cs-3-col-row-v1')
  };

  Drupal.behaviors.customerService3ColRowV1 = {
    get: function (key) {
      return customerService3ColRowV1[key];
    },

    set: function (key, val) {
      customerService3ColRowV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function (i) {
        var thisFormatter = $(this);
      // code goes here
      });
    }
  };
})(jQuery);
